@import '../../App.scss';

.container {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.howToUse {
  max-width: 584px;
  width: 100%;
  height: 44px;
  background-color: rgba(186, 170, 255, 0.1);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  border: 2px solid var(--dark-purple-color);
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.mosaicRow {
  display: flex;
  flex-direction: row;
  position: relative;
  > img {
    position: absolute;
    width: 95px;
    top: 42%;
    left: -150px;
    border-radius: 5px;
    cursor: pointer;
  }
}
.skeleton {
  width: 600px;
  height: 732px;
}
.hidden {
  display: none;
}
.popup {
  position: absolute;
  display: flex;

  flex-direction: column;
  align-items: center;
  gap: 41px;
  background: transparent;
  > img {
    width: 620px;
    border-radius: 13px;
    border: 4px solid white;
    -webkit-box-shadow:  0 0 0 9999px rgba(0, 0, 0, 0.5);
    box-shadow:  0 0 0 9999px rgba(0, 0, 0, 0.5);
  }
  z-index: 999999;
}
.swiperWrapper {
  margin-top: 17px;
}
.swiperContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 13px;
  max-width: 755px;
  width: 100%;
  position: relative;
}
.slide {
  background-color: rgba(186, 170, 255, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  > span {
    font-family: 'Comfortaa', sans-serif;
    font-size: 36px;
    font-weight: 700;
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  cursor: pointer;
}
.nextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 75px;
  width: 100%;
  height: 75px;
  border-radius: 25px;
  margin-left: 32px;
  transition: all 0.3s;
  &:hover, &:active {
    cursor: pointer;
  }
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.prevButton {
  @extend .nextButton;
  margin-left: 0;
  margin-right: 32px;
  svg {
    transform: rotate(180deg);
  }
}
.clickedSector {
  border: 2px solid var(--dark-purple-color);
  background-clip: padding-box;
}
.swiperText {
  align-self: flex-start;
  margin-left: 110px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: left;
}
.bottomContainer {
  > span {
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 18.29px;
    text-align: left;
  }
  max-width: 540px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 17px;
}
.instructionTextRow {
  display: flex;
  flex-direction: row;
  gap: 10px;
  > img {
    display: none;
    width: 82px;
    height: auto;
    border-radius: 5px;
  }
}
.instructionText {
  gap: 17px;
  font-family: Montserrat, sans-serif;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.29px;
  text-align: left;

  a {
    text-decoration: underline;
  }
}
.scrollbarRow {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: 56px;
  > div > svg {
    position: absolute;
    width: 20px;
  }
  .left {
    left: -32px;
    transform: rotate(180deg);
  }
  .right {
    right: -32px;
  }
}

.customInput {
  display: flex;
  width: 100%;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;

  > span {
    font-size: 20px;
    font-weight: 500;
  }

}
@media screen and (max-width: 930px) {
  .mosaicRow {
    >img {
      display: none;
    }
  }
  .instructionTextRow {
    >img {
      display: block;
    }
  }
}

@media screen and (max-width: $min-tablet-width) {
  .skeleton {
    width: 451px;
    height: 391px;
  }  
  .howToUse {
    max-width: 331px;
    height: 25px;
    border-radius: 6px;
    font-size: 14px;
  }
  .customInput {
    max-width: 331px;
    margin-bottom: 20px;
    > span {
      font-size: 12px;
    }
  }
  .slide {
    border-radius: 8px;
    > span {
      font-size: 20px;
      width: 100%;
      height: 40px;
    }
  }
  .swiperContainer {
    max-width: 375px;
  }
  .nextButton {
    width: 36px;
    min-width: 36px;
    height: 36px;
    margin: 3px;
    svg {
      width: 15px;
    }
  }
  .bottomContainer {
    > span, .instructionText {
  font-size: 10px;
  line-height: 12.29px;  
    }
    max-width: 328px;
    margin-top: 11px;
  }
  .swiperText {
    font-size: 14px;
    margin-left: 35px;
    line-height: 17px;
  }
  .instructionTextRow {
    margin-top: 11px;
  }
  .scrollbarRow {
    margin-bottom: 22px;
    > div > svg {
      top: -3px;
      width: 7px;
    }
    .left {
      left: -17px;
    }
    .right {
      right: -17px;
    }
  }
  .popup {
    img {
      width: 320px;
    }
  }
}

