@import '../../App.scss';
@import '../../theme/breakpoints.scss';

.purchaseContainer {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;
  gap: 38px;
  h1 {
    font-family: 'Comfortaa';
    font-size: 40px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
  }
  > div > button {
    margin-left: 0;
  }
  @media screen and (max-width: $max-tablet-width) {
    align-items: center;
    gap: 13px;
    > div > button {
      margin-left: auto;
    }  
  }

}
.marketplacesBlocks {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 29px;
  justify-content: center;
}


@media screen and (max-width: 1050px) {
  .marketplacesBlocks {
    flex-direction: column;
  }
}  
@media screen and (max-width: $min-tablet-width) {
  .marketplacesBlocks {
    margin-top: 19px;
    flex-direction: column;
    gap: 13px;
  }
  .purchaseContainer {
    h1 {
      font-size: 24px;
      line-height: 28px;
    }
  }
}  
