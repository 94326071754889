@import '../../App.scss';
@import '../../theme/breakpoints.scss';

.emailInput {
  width: 688px;
  margin-bottom: 32px;
  font-family: 'Montserrat';
  font-size: 21px;
  font-weight: 500;
  border-radius: 12px;
  background-color: white;
  @extend .input;

  @media screen and (max-width: $max-tablet-width) {
    width: 500px;
  }
  @media screen and (max-width: $min-tablet-width) {
    border-radius: 5px;
    width: 350px;
  }  
  @media screen and (max-width: $phone-width) {
    width: 302px;
    }  
}

.emailInputError {
  @extend .emailInput;

  outline: 1px solid var(--error-color)
}