@import '../../theme/colors.scss';
@import '../../theme/breakpoints.scss';

.pageContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  > div > button {
    position: absolute;
    left: 40px;
  }
  input {
    position: absolute;
    top: 18%;
    width: 90%;
    height: 55%;
    z-index: 1000;
    opacity: 0;
  }

  h1 {
    font-family: 'Comfortaa';
    font-size: 40px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 25px;
    max-width: 700px;
    }
    .text {
      font-family: 'Montserrat';
      font-size: 37px;
      font-weight: 500;
      line-height: 35px;
      text-align: center;
      margin-top: 74px;
    }
    .description {
      font-family: 'Montserrat';
      font-size: 24px;
      font-weight: 500;
      line-height: 35px;
      color: var(--grey-color-300);
      text-align: center;
    }
    svg {
      margin: 32px auto;
    }
  .wrapperMainContent {
    max-width: 831px;
    width: 100%;
    position: relative;
  }
  .message {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 500;
    line-height: 35px;
    color: var(--grey-color-300);
    text-align: center;
    margin-bottom: 50px;
  }
}
.button {
  z-index: 1000000;
}
.wrapperMobileContent {
  display: none;
}
@media screen and (max-width: 1200px) {
  .pageContent {
    flex-direction: column-reverse;
    gap: 20px;
    > div > button {
      position: static;
    }  
  }
}
@media screen and (max-width: $max-tablet-width) {
  .wrapperMainContent {
    display: none;
  }
  .wrapperMobileContent {
    display:flex;
    width: 100%;
    max-width: 500px;
    h1 {
      font-family: 'Montserrat';
      font-size: 18px;
      font-weight: 500;
      line-height: 17px;
      text-align: center;
      margin-bottom: 0;
      margin-top: 43px;
      width: 90%;
    }
    button {
      margin-bottom: 43px;
    }
    .message{
      margin: 5px auto;
      font-size: 11px;
    }
  }
}