@import '../../App.scss';

.grid {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 54px;
  row-gap: 12px;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}
.wrapper {
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media screen and (max-width: $max-tablet-width) {
    align-items: center;
    gap: 13px;
  }

}