@font-face {
  font-family: 'Comfortaa';
  src: url('../../public/fonts/ComfortaaBold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../public/fonts/MontserratRegular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../public/fonts/MontserratMedium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../public/fonts/MontserratSemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../public/fonts/MontserratBold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}