@import '../../theme/breakpoints.scss';
@import '../../App.scss';
@import '../../theme/colors.scss';

.block {
  display: flex;
  justify-content: center;
  flex-direction: row;
  max-width: 1280px;
  width: 100%;
  height: 100%;
  border-radius: 54px;
  overflow: hidden;
}
.block_main {
  @extend .block;
  padding: 0 151px;

}
.horizontal {
  text-align: center;
  display: flex;
  flex-direction: row;
  height: 229px;
  align-items: center;
  gap: 10px;
  width: 81%;
}

.vertical {
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 550px;
  width: 100%;
  button {
    margin-top: 53px;
  }
}

.text {
  font-family: 'Comfortaa';
  font-weight: 700;
  font-size: 40px;
  color: black;
}
.longTextMobile {
  font-size: 23px !important;
  width: 300px;
}
.description {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 40px;
  color: white;
}
.image {
  width: 470px;
  height: 425px;
}

@media screen and (max-width: 1000px) {
  .block {
    flex-direction: column;
    padding: 42px 30px 0px 30px;
    align-items: center;
    gap: 33px;
    border-radius: 30px;
  }
  .image {
    margin-left: 0;
  }
    .horizontal {
      margin: -30px 0px 30px 0px;
      align-items:center ;
      flex-direction: column;
      gap: 0;
      justify-content: center;
  }
  .vertical {
    margin-left: 0;
  }
}

@media screen and (max-width: 600px) {
  .image {
    width: 302px;
    height: 271px;
  }
  .horizontal {
    height: 150px;
    .text, .description{
      font-size: 29px;
    }
    button {
      margin-top: -60px;
    }
  }
  .vertical {
    .text, .description {
      font-size: 24px;  
    }
    button {
      margin-top: 10px;
    }  
  }
}
