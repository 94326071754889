@import '../../../theme/colors.scss';
@import '../../../theme/breakpoints.scss';

.popUpContainer {
  z-index: 1000;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 24px;
  width: 482px;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.header {
font-family: 'Comfortaa';
font-size: 40px;
font-weight: 700;
line-height: 45px;
text-align: center;
}
.mainImg {
  max-height: 525px;
  margin: 18px 0;
  border-radius: 15px;
}

.bigImg {
  max-height: 525px;
}

.smallImg {
  width: 155px;
  max-height: 206px;
  border-radius: 25px;
  margin: 5px;
  border: 4px solid transparent;
}

.clickedImg {
  border: 4px solid var(--dark-purple-color);
  background-clip: padding-box;
}

.okButton {
  margin-top: 20px;
}
.slider {
  transition: all 0.3s;
  user-select: none;
  display: flex;
  flex-direction: row;
  padding: 18px 21px;
  gap: 19px;
  width: 1080px;
}
.nextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 75px;
  width: 100%;
  height: 75px;
  background-color: var(--purple-color);
  border-radius: 25px;
  margin-left: 32px;
  transition: all 0.3s;
  &:hover, &:active {
    cursor: pointer;
    background-color: var(--dark-purple-color);
  }
}

.prevButton {
  @extend .nextButton;
  margin-left: 0;
  margin-right: 32px;
  svg {
    transform: rotate(180deg);
  }
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.buttonContainer {
  display: flex;
  user-select: none;
  flex-direction: row;
  max-width: 548px;
  width: 100%;
  justify-content: space-between;
  margin: 40px auto;
}
.slide {
  width: 172px;
}

@media screen and (max-width: 1280px) {
  .nextButton, .prevButton {
    margin: 5px;
  }
  .slider {
    width: 730px;
  }
}
@media screen and (max-width: $max-tablet-width) {
  .overflow {
    max-width: 563px;
    width: 100%;
  }
  .slider {
    gap: 10px;
    width: 563px;
  }
}
@media screen and (max-width: $min-tablet-width) {
  .mainImg {
    max-height: 346px;
    margin: 18px 0;
    border-radius: 15px;
  }
  .header {
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
  }
  .bigImg {
    max-height: 346px;
  }
  .slide {
    width: 79px;
    border-radius: 10px;
  }
  .smallImg {
    width: 74px;
    max-height: 96px;
    border-radius: 10px !important;
    border: 2px solid transparent;
  }
  .nextButton {
    min-width: 36px;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    padding: 0;
    svg {
      width: 15px;
    }
  }
  .slider {
    width: 255px;
    gap: 0;
    padding: 7px 9px;
  }
  .buttonContainer {
    max-width: 299px;
    margin: 20px auto;
    gap: 10px;
    
  }  
  .clickedImg {
    border: 2px solid var(--dark-purple-color);
    background-clip: padding-box;
    }
}
