@import '../../theme/colors.scss';
@import '../../theme/breakpoints.scss';

input[type='checkbox'] {
  display: none;
}
.personalData {
  text-decoration:underline;
  cursor: pointer;
}
input[type='checkbox'] + label:before {
  content: '';
  display: inline-block;
  background-color: var(--white-color);
  border: 1px solid var(--purple-color);
  font-size: 19px;
  line-height: 25px;
  margin: -5px 5px 0 0;
  height: 24px;
  width: 24px;
  border-radius: 8px;
  text-align: center;
  vertical-align: middle;
  transition: all 0.3s ease-out;
}
.label {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;
  user-select: none;
}
input[type='checkbox']:checked + label:before {
  content: url(../../assets/tick.svg);
  border-color: var(--white-color);
}

@media screen and (max-width: $min-tablet-width) {
  .label {
    font-size: 10px;
  }
  input[type='checkbox'] + label:before {
    height: 15px;
    width: 15px;  
    border-radius: 5px;
    font-size: 9px;
    line-height: 20px;  
  }
  input[type='checkbox']:checked + label:before {
    content: url(../../assets/tickSmall.svg);
  }  
}
