@import '../../theme/colors.scss';
@import '../../theme/breakpoints.scss';

.contentContainer {
  max-width: 1034px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  margin: 0 auto;
  h2 {
    font-family: 'Montserrat';
    font-size: 34px;
    font-weight: 500;
    line-height: 35px;
  }
}
.customSurface {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 40px;
  background-color: white;
  max-width: 1034px;
  margin: 0 auto;
  border-radius: 20px;
}
.text {
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 500;
}
.textWrapper {
  display: flex;
}
.image {
  width: 286px;
  height: 202px;
  border-radius: 20px;
  border: 4px solid #BAAAFF;
  margin-left: 40px;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  max-width: 683px;
  width: 100%;
  justify-content: space-between;
  margin: 10px auto;
}
.block {
  width: 100%;
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
.title {
  width: 100%;
}

@media screen and (max-width: $min-tablet-width) {
  .contentContainer {
    gap: 13px;
    h2 {
      font-size: 16px;
      line-height: 17px;
    }
  }
  .block {
    gap: 20px;
    padding: 34px 27px;
  }
  .buttonContainer {
  flex-direction: column-reverse;
  align-items: center;
  gap: 13px
  }
  .customSurface {
    flex-direction: column;
    padding: 20px;
  }
  .image {
    width: calc(100% - 16px);
    margin-left: 16px;
    margin-top: 10px;
    height: 164px;
  }
  .text {
    font-size: 14px;
  }
}
