@import '../../../theme/breakpoints.scss';

.buttonContainer {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  gap: 22px;
  &__item {
    cursor: pointer;
  }
}

@media screen and (max-width: $min-tablet-width) {
  .buttonContainer {
    gap: 14px;
    &__item {
      cursor: pointer;
      width: 47px;
    }
  }
}
