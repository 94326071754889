@import '../../theme/colors.scss';
@import '../../theme/breakpoints.scss';

.contentContainer {
  max-width: 1034px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  margin: 0 auto;
  p {
    font-family: 'Montserrat';
    font-size: 34px;
    font-weight: 500;
    line-height: 35px;
    text-align: center;
  }
}
.customSurface {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 45px;
  background-color: var(--dark-purple-color);
  max-width: 1034px;
  margin: 0 auto;
  border-radius: 45px;
  p {
    color: white;
  }
  .mail {
    text-decoration: 2px underline;
  }
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  max-width: 683px;
  width: 100%;
  justify-content: space-between;
  margin: 10px auto;
}
.block {
  height: 315px;
  max-width: 718px;
  width: 100%;
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: $min-tablet-width) {
  .contentContainer {
    gap: 13px;
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 17px;
    }
  }
  .customSurface {
    border-radius: 25px;
    padding: 34px 0;
  }
  .block {
    height: 192px;
    gap: 20px;
    padding: 34px 27px;
  }
  .buttonContainer {
  flex-direction: column-reverse;
  align-items: center;
  gap: 13px
  }
  }
