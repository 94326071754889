@import '../../theme/colors.scss';
@import '../../theme/breakpoints.scss';
@import '../../App.scss';

.footer {
  background-color: #BAAAFF0A;
  border-radius: 60px 60px 0px 0px;
  @extend .flexCenter;
  padding: 52px 128px;
}
.innerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1280px;
  width: 100%;
}
.footerRight {
  display: flex;
  height: 68px;
  flex-direction: column;
  justify-content: space-between;
}
.support {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 26px;
}
.phone {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 20px;
}
.iconsWrapper {
  @extend .flexCenter;
  gap: 19px;
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    width: 62px;
    cursor: pointer;
    svg {
      opacity: 0.85;
      transition: all 0.3s;
      &:hover, &:active{
        opacity: 1;
      }  
    }
  }
}

.footerText {
  color: var(--grey-color-400);
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 600;
}
@media screen and (max-width: 1140px) {
  .footer {
    padding: 52px 30px
  }
}
@media screen and (max-width: 950px) {
  .footer {
    padding: 46px 36px;
  }
  .innerContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
  }
  .support {
    font-size: 20px;
  }
  .iconsWrapper {
    margin-bottom: 20px;
    justify-content: flex-start;
    gap: 10px;
    .footerText {
      font-size: 10px;
    }

    &__item {
      width: 52px;
      gap: 5px;
      font-size: 10px;
      svg {
        width: 47px;
      }
    }
  }
  .footerRight {
    height: 100%;
    gap: 20px;
  }
}
