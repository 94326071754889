@import '../../theme/breakpoints.scss';
@import '../../theme/colors.scss';

.wrapper {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 38px;
  @media screen and (max-width: $max-tablet-width) {
    align-items: center;
    gap: 13px;
  }
}
.faqPage {
  max-width: 1200px;
  width: 100%;
  margin: 51px 67px;
  h1 {
    font-family: 'Comfortaa';
    font-size: 32px;
    font-weight: 700;
    line-height: 35px;
    text-align: center;
    @media screen and (max-width: $max-tablet-width) {
      font-size: 20px;
    }
  }
  .faqList {
    width: 90%;
    list-style-type: none;
    margin: 0 auto;
  }
  .paragraphs {
    padding: 36px 50px;
    align-self: flex-start;
  }
  .listItem {
    margin-bottom: 32px;
    margin-top: 20px;
    border-bottom: 2px solid var(--dark-purple-color);
    transition: all 0.5s;
    &:nth-last-child(1) {
      border-bottom: none;
      margin-bottom: -10px;
    }
    .header {
      overflow: hidden;
      z-index: 100;
    }
    cursor: pointer;
    overflow: hidden;
    @media screen and (max-width: $phone-width) {
      margin-bottom: 20px;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      gap: 20px;
      margin-bottom: 18px;
      z-index: 100;
        font-family: 'Montserrat';
        font-size: 27px;
        font-weight: 500;
        @media screen and (max-width: $max-tablet-width) {
          font-size: 14px;
        }    
        @media screen and (max-width: $phone-width) {
          max-width: 322px;
        }
      .arrow {
        cursor: pointer;
        transition: all .1s;
        @media screen and (max-width: $max-tablet-width) {
          width: 9px;
        }
        margin-right: 10px;

      }
      .arrowActive {
        cursor: pointer;
        transition: all .1s;
        transform: rotate(-90deg);
      }
    }
  }
}
.listItemActive {
  border-color: transparent !important;
  height:auto;
  transition: all 0.5s;
}
.hidden {
  font-family: 'Montserrat';
  font-size: 21px;
  font-weight: 500;
  line-height: 27px;
  transition: all 0.5s;
  overflow: hidden;
  .paragraphs {
    transition: all 0.5s;
    margin-top: -100%;
  }
  opacity: 0;
  @media screen and (max-width: $max-tablet-width) {
    font-size: 13px;
    line-height: 18px;
    .paragraphs {
      padding: 14px 19px;
      margin-top: -200%;
    }  
  }
  .paragraph:not(:last-child) {
    margin-bottom: 16px;
  }
}
.visible {
  height: 100%;
  z-index: 1;
  margin-top: 0;
  transition: all 0.5s;
  opacity: 1;
  .paragraphs {
    transition: all 0.5s;
    margin-top: 0;
  }
}
