@import '../../theme/colors.scss';
@import '../../theme/breakpoints.scss';

.surface {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 65px;
}

.video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1100px;
  height: 700px;
  padding: 0px 65px 40px 65px;
  margin: 0 auto;
  border-radius: 21px;
}

.surface-border {
  @extend .surface;
  width: 838px;
  height: 560px;
  background-color: transparent !important;
  border-radius: none;
  background-image: url(../../assets/surfaceBorder.svg);
}

@media screen and (max-width: 1150px) {
  .video {
  width: 750px;
  height: 500px;
  }
}
@media screen and (max-width: $min-tablet-width) {
  .surface {
    border-radius: 35px;
    background-image: none !important;
  }
  .video {
    border-radius: 12px;
    padding: 0px 18px 11px 18px;
    width: 450px;
    height: 300px;
  }
}

@media screen and (max-width: $phone-width) {
  .video {
    width: 350px;
  }
}
