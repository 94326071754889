@import '../../App.scss';
@import '../../theme/breakpoints.scss';

.purchaseContainer {
  flex-direction: column;
  display: flex;
  max-width: 1200px;
  width: 100%;
  align-items: left;
  h2 {
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 500;
    line-height: 50px;
  }
  button {
    margin-left: 0;
  }
}

.video {
  border-radius: 21px;
}
.wrapper {
  text-align: left;
  margin-bottom: 37px;
  width: 100%;
}

@media screen and (max-width: $min-tablet-width) {
  .purchaseContainer {
    align-items: center;
    button {
      margin-left: auto;
    }
  }
  .video {
    border-radius: 12px;
  }
  .purchaseContainer {
    h2 {
      font-size: 16px;
      line-height: 30px;
    }
  }
}
