@import '../../theme/colors.scss';
@import '../../theme/breakpoints.scss';

.burger {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  right: -20px;
  top: 50px;
  width: 252px;
  border-radius: 0 0 0 30px;
  display: flex;
  justify-content: center;
  z-index: 100000;
}
.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  gap: 30px;
  margin-bottom: 32px;
}
.faq {
  cursor: pointer;
  font-family: 'Comfortaa';
  text-align: right;
  &:hover {
    text-decoration: underline;
    transition: all 0.5s;
  }

}
.languageButton {
  display: flex;
  gap: 10px;
  margin-right: -20px;
  transition: all 0.5s;
  &:hover {
    text-decoration: underline;
    transition: all 0.5s;
  }
}
.polygon {
  transition: all 0.5s;
  transform: rotate(-90deg);
}
.dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -10px;
  gap: 14px;
}