@import '../../App.scss';

.blocks {
  display: flex;
  flex-direction: column;
  gap: 28px;
  > div > button{
    margin-left: 0px;
  }
  @media screen and (max-width: $max-tablet-width) {
    align-items: center;
    gap: 13px;
    > div > button{
      margin-left: auto
    }  
  }
}